import TitleBlock from "../../components/TitleBlock";
import SliderNews from "../../components/News/SliderNews";
import {  useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import FixedPriceFirst from "../../components/TarifClients/FixedPriceFirst";
import FixedPriceSecond from "../../components/TarifClients/FixedPriceSecond";
import FixedPriceThird from "../../components/TarifClients/FixedPriceThird";
import DownloadTarifs from "../../components/TarifClients/DownloadTarifs";
// import TarifOsr from "../../components/TarifClients/TarifOsr";

const TarifCli = () => {

    useEffect(() => {
      window.scrollTo(0,0);
    }, [])
    
    const [,,theme] = useOutletContext();

    
    return (
       <>
       {theme === 'light' && <div className="mont-block"></div>}
         <TitleBlock title={'Тарифи на електроенергію'} type={'Для населення'}/>
         <div className="attention-cli">
            <Container>
                <Row>
                    <Col md={6}>
                    <div className="clitarif-attention">
                        <h4>Зверніть увагу!</h4>
                        <p>Фіксована ціна на електричну енергію для побутових споживачів затверджена <Link to='https://zakon.rada.gov.ua/laws/show/483-2019-%D0%BF#n516' target="_blank">Постановою Кабінету Міністрів України № 483 від 05.06.2019 року</Link> «Про затвердження Положення про покладення спеціальних обов'язків 
                            на учасників ринку електричної енергії для забезпечення загальносуспільних інтересів у процесі функціонування ринку електричної енергії» із змінами та доповненнями.</p>
                    </div>
                    </Col>
                    <Col md={6}>
                    <div className="clitarif-attention">
                        <h4>Зверніть увагу!</h4>
                        <p><Link to='/pdf/dod_protokol.pdf' target="_blank">
                            Алгоритм дій для побутових споживачів, операторів систем розподілу (далі — OCP) та постачальників універсальннх послуг (далі — ПУП) для підтвердження обладнання житлового 
                            будинку електроопалювальною установкою в установленому порядку та можливості застосування фіксованої (зниженої) ціни на електричну енергію з 1 жовтня по 30 квітня (включно).
                            <br /> Алгоритм дій є спільною узгодженою позицією НКРЕКП, Міністерства енергетики України, Міністерства розвитку громад, територій та інфраструктури України та Державної інспекції енергетичного нагляду України.
                        </Link>
                        </p>
                    </div>
                    </Col>
                </Row>
            </Container>
         </div>
         <section className="fixed-price">
             <TitleBlock subtitle={'Фіксовані ціни на електричну енергію для побутових споживачів'} addClass ='tarif-pobut-title'/>
             <FixedPriceFirst/>
             <FixedPriceSecond/>
             <FixedPriceThird/>
         </section>
         <TitleBlock subtitle={'Тарифи на електроенергію, що відпускається населенню'} addClass ='tarif-pobut-title'/>
         <DownloadTarifs/>
         {/* <TitleBlock subtitle={'Складові фіксованої ціни на електроенергію для населення затверджені НКРЕКП'} addClass ='tarif-pobut-title'/>
         <TarifOsr/> */}
         <SliderNews/>
      </>
    );
};

export default TarifCli;